import { CustomTyphography, Flexbox, Loader } from 'components';
import classNames from 'classnames/bind';
import styles from '../styles.module.scss';
import { FC } from 'react';
import { StatusTypes } from 'utils/types';
import { useSelector } from 'react-redux';
import { teamsSelector } from 'store/teams-slice';
import { useGetTeamsQuery } from 'store/teams-api';
import { useWorkspaceId } from 'utils/hooks';

const classes = classNames.bind(styles);

interface OverviewProps {
    totalProjects: number;
    activeProjects?: number;
    overallRisk?: StatusTypes;
    businessImpact?: string;
    projectsLoading?: boolean;
}

const Overview: FC<OverviewProps> = ({
    totalProjects,
    activeProjects,
    overallRisk,
    businessImpact,
    projectsLoading
}) => {
    const workspaceId = useWorkspaceId();
    const { isLoading } = useGetTeamsQuery({ workspaceId });

    const teamsData = useSelector(teamsSelector);

    return (
        <Flexbox vertical className={classes('overview', 'section', 'p-5', 'gap-4')}>
            <Flexbox vertical className={classes('sectionHeader')}>
                <CustomTyphography className={classes('title')}>Overview</CustomTyphography>
                <CustomTyphography className={classes('description')}>Number of initiatives per status</CustomTyphography>
            </Flexbox>
            <Flexbox stretch className={classes('gap-2')} wrap>
                <Flexbox className={classes('contentBlock', 'p-3')}>
                    <Flexbox fullWidth vertical className={classes('gap-3')}>
                        <Flexbox className={classes('title')}>Project Portfolio</Flexbox>
                        <Flexbox vertical fullWidth className={classes('gap-1')}>
                            <Flexbox align justifyBetween className={classes('item')}>
                                <Flexbox className={classes('label')}>
                                    Total Projects
                                </Flexbox>
                                {projectsLoading ? <Loader size={20} /> : <Flexbox className={classes('value')}>{totalProjects}</Flexbox>}
                            </Flexbox>
                            <Flexbox align justifyBetween className={classes('item')}>
                                <Flexbox className={classes('label')}>
                                    Active Projects
                                </Flexbox>
                                {projectsLoading ? <Loader size={20} /> : <Flexbox className={classes('value')}>{activeProjects}</Flexbox>}
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>

                <Flexbox className={classes('contentBlock', 'p-3')}>
                    <Flexbox fullWidth vertical className={classes('gap-3')}>
                        <Flexbox className={classes('title')}>Resources</Flexbox>
                        <Flexbox vertical fullWidth className={classes('gap-1')}>
                            <Flexbox align justifyBetween className={classes('item')}>
                                <Flexbox className={classes('label')}>
                                    Team Members
                                </Flexbox>
                                {isLoading ? <Loader size={20} /> : <Flexbox className={classes('value')}>{teamsData.length}</Flexbox>}
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>

                <Flexbox className={classes('contentBlock', 'p-3')}>
                    <Flexbox fullWidth vertical className={classes('gap-3')}>
                        <Flexbox className={classes('title')}>Risk and Impact</Flexbox>
                        <Flexbox vertical fullWidth className={classes('gap-1')}>
                            <Flexbox align justifyBetween className={classes('item', [overallRisk ? overallRisk?.toLowerCase() : ''])}>
                                <Flexbox className={classes('label')}>
                                    Overall Risk
                                </Flexbox>
                                {projectsLoading ? <Loader size={20} /> : <Flexbox className={classes('value')}>{overallRisk || 'No Risk Calculated'}</Flexbox>}
                            </Flexbox>
                            <Flexbox align justifyBetween className={classes('item', [businessImpact ? businessImpact?.toLowerCase() : ''])}>
                                <Flexbox className={classes('label')}>
                                    Business Impact
                                </Flexbox>
                                {projectsLoading ? <Loader size={20} /> : <Flexbox className={classes('value')}>{businessImpact}</Flexbox>}
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}

export default Overview