import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const roadmapMetricsApi = createApi({
    reducerPath: 'roadmapMetrics.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['roadmapMetrics'],
    endpoints: builder => ({
        getInitiatives: builder.query({
            query: ({ workspaceId }) => `/workspaces/${workspaceId}/initiatives`,
        }),
    })
});

export const {
    useGetInitiativesQuery,
    useLazyGetInitiativesQuery
} = roadmapMetricsApi