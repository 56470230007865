const ChartBar = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_6269_1017)">
                <path opacity="0.2" d="M16.5625 3.125H12.1875V16.25H16.5625V3.125Z" fill="currentColor" />
                <path d="M17.8125 16.25H2.1875" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.8125 16.25V6.875H12.1875" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.5625 3.125H12.1875V16.25H16.5625V3.125Z" fill="currentColor" fill-opacity="0.2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.4375 16.25V10.625H7.8125" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_6269_1017">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ChartBar