export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function snakeToTitleCase(input: string): string {
    return input
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const camelCaseToNormal = (str: string): string => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^./, (char) => char.toUpperCase());
};

export function isMarkdown(text: string): boolean {
    const markdownPatterns = [
        /(^|\s)(#{1,6}\s)/, // Headers
        /(\*\*|__)(.*?)\1/, // Bold
        /(\*|_)(.*?)\1/, // Italics
        /\[(.*?)\]\((.*?)\)/, // Links
        /!\[(.*?)\]\((.*?)\)/, // Images
        /^(\s*[-*]\s)/m, // Lists
        /(`{1,3})(.*?)\1/, // Inline code
        /^(\s*>)/m // Blockquotes
    ];

    return markdownPatterns.some(pattern => pattern.test(text));
}

export const isHtmlLike = (str: string): boolean => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};