import { FC, useState } from 'react';
import classNames from 'classnames/bind';

import { Pie, PieChart, Tooltip } from 'recharts'
import { camelCaseToNormal } from 'utils/string';
import styles from './styles.module.scss'

const classes = classNames.bind(styles);

interface CustomPieChartProps {
    width: number,
    height: number,
    data: { [key: string]: string | number }[],
    dataKey?: string,
    nameKey?: string,
    infoText?: string
}

const CustomPieChart: FC<CustomPieChartProps> = ({
    width,
    height,
    data,
    dataKey = 'value',
    nameKey = 'name',
    infoText
}) => {
    const [activeSector, setActiveSector] = useState<any>(null);

    const handleMouseEnter = (_: unknown, index: number) => {
        setActiveSector(data[index]);
    };

    const handleMouseLeave = () => {
        setActiveSector(null);
    };

    return (
        <div className={classes('custom-pie-chart')} style={{ minWidth: width, minHeight: height }}>
            <PieChart width={width} height={height}>
                <Pie
                    activeIndex={0}
                    data={data}
                    dataKey={dataKey}
                    nameKey={nameKey}
                    cx="50%"
                    cy="50%"
                    innerRadius={90}
                    outerRadius={110}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
                {!!infoText && <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    className={classes('inner-text')}
                >
                    {activeSector?.count || ''} {infoText}
                </text>}

                <Tooltip
                    formatter={(value, name) => {
                        return [`${value}%`, camelCaseToNormal(name as string)]
                    }}
                />
            </PieChart>
        </div>
    )
}

export default CustomPieChart;