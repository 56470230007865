import { createSlice } from '@reduxjs/toolkit'
import { Initiative } from 'utils/types'
import { roadmapMetricsApi } from './roadmapMetrics-api';
import { RootState } from 'store';

interface RoadmapMetricsState {
    initiatives: Initiative[],
}

const initialState: RoadmapMetricsState = {
    initiatives: [],
}

const roadmapMetricsSlice = createSlice({
    name: 'roadmapMetrics',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            roadmapMetricsApi.endpoints.getInitiatives.matchFulfilled,
            (state, { payload }: { payload: Initiative[] }) => {
                state.initiatives = payload
            },
        )
    },
})

export const initiativesSelector = (store: RootState) => store.roadmapMetrics.initiatives;

export const { } = roadmapMetricsSlice.actions

export default roadmapMetricsSlice
