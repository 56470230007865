import { Button, CommonButton, Flexbox, SearchField, Typography } from 'components';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import classNames from 'classnames/bind';
import styles from '../styles.module.scss'
import { FilterListIcon } from 'components/icons';

const classesInfo = classNames.bind(stylesInfo);
const classes = classNames.bind(styles);

const Header = () => {
    return (
        <Flexbox align justifyBetween fullWidth className={classes('header')}>
            <Flexbox align className={classes('gap-4')}>
                <Typography className={classes('title')}>Roadmap Metrics</Typography>
                <SearchField
                    // value={searchValue}
                    // onChange={handleSearchValueChange}
                    // onClear={handleSearchClear}
                    placeholder='Search initiatives'
                    className={classesInfo('searchInput')}
                />
            </Flexbox>

            <Flexbox align className={classes('gap-2')}>
                <CommonButton buttonType='shadow' buttonSize='small'>
                    <FilterListIcon />
                    Filters
                </CommonButton>
                <Button variant='contained'>Download Report</Button>
            </Flexbox>
        </Flexbox>
    )
}

export default Header