import { Flexbox, Grid, Loader } from 'components'
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import Header from './components/Header';
import Overview from './components/Overview';
import StatisticPieChart from './components/StatisticPieChart';
import { useSelector } from 'react-redux';
import { useWorkspaceId } from 'utils/hooks';
import { useGetInitiativesQuery } from 'store/roadmapMetrics-api';
import { initiativesSelector } from 'store/roadmapMetrics-slice';
import { InitiativeStatus, StatusTypes } from 'utils/types';
import CustomLineChart from 'components/Charts/CustomLineChart';

const classes = classNames.bind(styles);

// THIS IS MAINLY MOCKED DATA & TEST OPERATIONS
export enum ProjectStatus {
    Done = 'done',
    Active = 'active',
    OnHold = 'onHold'
}

type Project = {
    status: ProjectStatus;
};

type Risk = {
    value: number | null;
    category: StatusTypes | 'NotCalculated'
};

type StatusSummary = {
    status: ProjectStatus;
    count: number;
    percentage: number;
};

type RiskSummary = {
    risk: StatusTypes | 'NotCalculated';
    count: number;
    percentage: number;
};

const calculateStatusSummary = (projects: Project[]): StatusSummary[] => {
    const total = projects.length;

    const statusCounts = projects.reduce<Record<ProjectStatus, number>>(
        (acc, project) => {
            acc[project.status] = (acc[project.status] || 0) + 1;
            return acc;
        },
        {
            [ProjectStatus.Done]: 0,
            [ProjectStatus.Active]: 0,
            [ProjectStatus.OnHold]: 0,
        }
    );

    return Object.entries(statusCounts).map(([status, count]) => ({
        status: status as ProjectStatus,
        count,
        percentage: total > 0 ? Number(((count / total) * 100).toFixed(2)) : 0,
        fill: status === ProjectStatus.OnHold ? '#FBCFE8' : status === ProjectStatus.Done ? '#BBF7D0' : '#C7D2FE'
    }));
};

const calculateRiskSummary = (risks: Risk[]): RiskSummary[] => {
    const total = risks.length;

    const categoryCounts = risks.reduce(
        (acc, risk) => {
            acc[risk.category] = (acc[risk.category] || 0) + 1;
            return acc;
        },
        {
            [StatusTypes.Critical]: 0,
            [StatusTypes.High]: 0,
            [StatusTypes.Medium]: 0,
            [StatusTypes.Low]: 0,
            ['NotCalculated']: 0
        }
    );

    return Object.entries(categoryCounts).map(([category, count]) => ({
        risk: category as StatusTypes,
        count,
        percentage: total > 0 ? Number(((count / total) * 100).toFixed(2)) : 0,
        fill: category === 'NotCalculated' ? 'gray' :
            category === StatusTypes.Critical ? '#b61928' :
                category === StatusTypes.High ? '#FC8790' :
                    category === StatusTypes.Medium ? '#BBD1F7' : '#FFDEBD'
    }));
};

const calculateOverallRisk = (data: { risk: string; count: number }[]) => {
    const filteredData = data.filter((item) => item.count > 0 && item.risk !== 'NotCalculated');
    if (filteredData.length !== 0) {
        return filteredData.reduce((max, item) => (item.count > max.count ? item : max)).risk as StatusTypes;
    }
}

const findMostFrequentRisk = (risks: string[]) => {
    const frequencyMap: Record<string, number> = {};

    if (risks) {
        risks.forEach((risk) => {
            frequencyMap[risk] = (frequencyMap[risk] || 0) + 1;
        });

        return Object.keys(frequencyMap).reduce((a, b) =>
            frequencyMap[a] > frequencyMap[b] ? a : b,
            ''
        );
    }
};

const RoadmapMetrics = () => {
    const workspaceId = useWorkspaceId();
    const { isLoading } = useGetInitiativesQuery({ workspaceId })

    const initiatives = useSelector(initiativesSelector)

    const impactData = initiatives
        .flatMap(el => el.okrs)
        .flatMap(okr => okr.contributions)
        .map(contribution => contribution.impact || '');

    const projectChartData = initiatives.map((initiative) => {
        if (initiative.status === InitiativeStatus.Backlog || initiative.status === InitiativeStatus.Conception
            || initiative.status === InitiativeStatus.Research
        ) {
            return {
                status: ProjectStatus.OnHold,
            }
        } else if (initiative.status === InitiativeStatus.Live || initiative.status === InitiativeStatus.Sunsetted
            || initiative.status === InitiativeStatus['Development done'] || initiative.status === InitiativeStatus.Canceled || initiative.status === InitiativeStatus.Paused
            || initiative.status === InitiativeStatus.Blocked
        ) {
            return {
                status: ProjectStatus.Done,
            }
        } else {
            return {
                status: ProjectStatus.Active,
            }
        }
    })

    const groupedProjectsData = calculateStatusSummary(projectChartData)

    const riskChartData = initiatives.map(initiative => ({
        value: initiative.delayRisk ? initiative.delayRisk.value : null,
        category: initiative.delayRisk ? initiative.delayRisk.category : 'NotCalculated',
    }))

    const groupedRiskData = calculateRiskSummary(riskChartData as Risk[])
    const overallRisk = calculateOverallRisk(groupedRiskData);
    const businessImpact = findMostFrequentRisk(impactData)

    return (
        <Flexbox vertical fullWidth className={classes('roadmapMetrics', 'p-4')}>
            {/* Header */}
            <Header />
            {/* ______________Main Content_______________ */}
            <Flexbox vertical className={classes('gap-3')}>
                {/*________________ Project Portfolio Dashboard________________________ */}
                {/* ________________________Overview_______________________ */}
                <Overview
                    totalProjects={initiatives.length}
                    activeProjects={groupedProjectsData.find(project => project.status === ProjectStatus.Active)?.count}
                    overallRisk={overallRisk}
                    businessImpact={businessImpact}
                    projectsLoading={isLoading}
                />
                {/* __________________Pie Charts_______________ */}
                <Grid container spacing={3}>
                    <Grid item lg={6} md={12} xs={12}>
                        {isLoading ? <Flexbox align justify fullHeight><Loader /></Flexbox> : <StatisticPieChart
                            dataKey="percentage"
                            nameKey="status"
                            data={groupedProjectsData}
                            title="Project Status"
                            description="Number of initiatives per status"
                            infoText="Project"
                        />}
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        {isLoading ? <Flexbox align justify fullHeight><Loader /></Flexbox> : <StatisticPieChart
                            dataKey="percentage"
                            nameKey="risk"
                            data={groupedRiskData}
                            title="Risk Level"
                            description="Number of initiatives per status"
                            infoText="Projects at risk"
                        />}
                    </Grid>
                </Grid>
                <Flexbox vertical className={classes('section', 'p-5', 'gap-4')}>
                    <CustomLineChart />
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}

export default RoadmapMetrics