import classNames from 'classnames/bind';
import { Box, Flexbox, Typography } from 'components';
import CustomPieChart from 'components/Charts/CustomPieChart';
import styles from '../styles.module.scss';
import { FC } from 'react';
import { camelCaseToNormal } from 'utils/string';

const classes = classNames.bind(styles);

interface StatisticPieChartProps {
    title: string;
    description: string;
    dataKey?: string;
    nameKey?: string;
    data: {
        [key: string]: any
    }[],
    infoText?: string
}

const StatisticPieChart: FC<StatisticPieChartProps> = ({ title, description, dataKey, data, nameKey, infoText }) => {
    return (
        <Flexbox vertical fullHeight className={classes('staticPieChart', 'section', 'gap-8')}>
            <Flexbox vertical className={classes('sectionHeader')}>
                <Flexbox className={classes('title')}>{title}</Flexbox>
                <Flexbox className={classes('description')}>{description}</Flexbox>
            </Flexbox>
            <Flexbox align wrap className={classes('gap-10')}>
                <CustomPieChart
                    width={220}
                    height={220}
                    data={data}
                    dataKey={dataKey}
                    nameKey={nameKey}
                    infoText={infoText}
                />
                <Flexbox vertical className={classes('gap-4', 'legend-items')}>
                    <Typography className={classes('legend-title')}>{nameKey}</Typography>
                    <Flexbox wrap align className='gap-2'>
                        {data.map((entry, index) => (
                            <Flexbox
                                align
                                key={`legend-${index}`}
                                className='gap-1'
                            >
                                <Box className={classes('legend-dot')} sx={{ backgroundColor: entry.fill }} />
                                {nameKey && <Typography>{camelCaseToNormal(entry[nameKey])}</Typography>}
                                <Typography className={classes('legend-percentage')}
                                >
                                    {entry.percentage}%
                                </Typography>
                            </Flexbox>
                        ))}
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}

export default StatisticPieChart